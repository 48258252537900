require('bootstrap')
import mixitup from 'mixitup' // 2.1.11
// require('jquery.stellar/jquery.stellar')
require('./vendor/jquery.stellar.min.js')
// require('magnific-popup')
import counterUp from 'counterup2'
// require('waypoints/src/waypoint')
require('waypoints/lib/noframework.waypoints')
import AOS from 'aos'
// require('wow.js/src')
require('owl.carousel')
require('jquery-unveil')

;($ => {
  'use strict'

  $(document).ready(() => {
    /*
     * ----------------------------------------------------------------------------------------
     *  PRELOADER JS
     * ----------------------------------------------------------------------------------------
     */
    // $(window).on('load', () => {
      $('.text-rotator').each(function() {
        const $this = $(this)
        const text_rotator_content = $this.html()

        $this.empty()
        $this.html('<div class="rotator-wrap"></div>')

        const this_item = $this.children('.rotator-wrap')
        const text_rotator_content_split = text_rotator_content.split(',')
        const item_size = text_rotator_content_split.length

        nova_text_rotator(text_rotator_content_split, this_item, item_size)
      })

      function nova_text_rotator(item_array, this_item, item_size, my_index = -1) {
        my_index++

        if (my_index < item_size) {
          const speed = 8e2

          this_item.fadeOut(speed, () => {
            this_item.html('<span>' + item_array[my_index] + '</span>')
            this_item.fadeIn(speed)
          })
        } else {
          my_index = -1
        }

        window.setTimeout(() => {
          nova_text_rotator(item_array, this_item, item_size, my_index)
        }, 2500)
      }

      $('.loader').fadeOut()
      $('#preloader-area').delay(350).fadeOut('slow')
    // })

    /*
     * ----------------------------------------------------------------------------------------
     *  CHANGE MENU BACKGROUND JS
     * ----------------------------------------------------------------------------------------
     */
    $(window).on('scroll', () => {
      const $el = $('.header-top-area')
      const className = 'menu-bg'

      2e2 < $(window).scrollTop() ? $el.addClass(className) : $el.removeClass(className)
    })

    /*
     * ----------------------------------------------------------------------------------------
     *  SMOTH SCROOL JS
     * ----------------------------------------------------------------------------------------
     */
    $('a.smoth-scroll').on('click', e => {
      e.preventDefault()

      $('html, body').stop().animate({
        scrollTop: $($(e.currentTarget).attr('href')).offset().top - 50
      }, 1e3)
    })

    /*
     * ----------------------------------------------------------------------------------------
     *  PARALLAX JS
     * ----------------------------------------------------------------------------------------
     */
    $(window).stellar({
      responsive: true,
      positionProperty: 'position',
      horizontalScrolling: false,
    })

    /*
     * ----------------------------------------------------------------------------------------
     *  COUNTER UP JS
     * ----------------------------------------------------------------------------------------
     */
    Array.from(document.querySelectorAll('.counter-num')).forEach(element => {
      new Waypoint({
        element,
        handler() {
          counterUp(element, {
            delay: 10,
            duration: 1e3,
          })

          this.destroy()
        },
        offset: 'bottom-in-view',
      })
    })

    /*
     * ----------------------------------------------------------------------------------------
     *  WORK JS
     * ----------------------------------------------------------------------------------------
     */
    mixitup('#work .work-posts', {
      selectors: {
        target: '.col-md-4.mix'
      },
    })

    const $navbarCollapse1 = $('#navbar-collapse-1')

    $('#home button.navbar-toggle').on('click', e => {
      e.preventDefault()

      $navbarCollapse1.collapse('toggle')
    })

    /*
     * ----------------------------------------------------------------------------------------
     *  MAGNIFIC POPUP JS
     * ----------------------------------------------------------------------------------------
     */
    // const magnifPopup = function () {
    //   $('.work-popup').magnificPopup({
    //     type: 'image',
    //     removalDelay: 300,
    //     mainClass: 'mfp-with-zoom',
    //     gallery: {
    //       enabled: true
    //     },
    //     zoom: {
    //       enabled: true, // By default it's false, so don't forget to enable it

    //       duration: 300, // duration of the effect, in milliseconds
    //       easing: 'ease-in-out', // CSS transition easing function

    //       // The "opener" function should return the element from which popup will be zoomed in
    //       // and to which popup will be scaled down
    //       // By defailt it looks for an image tag:
    //       opener: function (openerElement) {
    //         // openerElement is the element on which popup was initialized, in this case its <a> tag
    //         // you don't need to add "opener" option if this code matches your needs, it's defailt one.
    //         return openerElement.is('img') ? openerElement : openerElement.find('img')
    //       }
    //     }
    //   })
    // }
    // // Call the functions
    // magnifPopup()

    /*
     * ----------------------------------------------------------------------------------------
     *  TESTIMONIAL JS
     * ----------------------------------------------------------------------------------------
     */
    $('.testimonial-list').owlCarousel({
      autoHeight: true,
      autoplay: true,
      autoplayHoverPause: true,
      autoplaySpeed: 1e3,
      autoplayTimeout: 5e3,
      dots: true,
      items: 1,
      loop: true,
      nav: false,
    })

    /*
     * ----------------------------------------------------------------------------------------
     *  GOOGLE MAP JS
     * ----------------------------------------------------------------------------------------
     */
    // const myCenter = new google.maps.LatLng(23.688272, 90.443162)

    // function initialize() {
    //     const mapProp = {
    //         zoom: 14,
    //         center: myCenter,
    //         scrollwheel: false,
    //         styles: [{
    //             "stylers": [{
    //                     "hue": "#ffffff"
    //                 }, {
    //                     saturation: -110
    //                 },
    //                 {
    //                     gamma: 2
    //                 }]
    //         }],

    //         mapTpeIdy: google.maps.MapTypeId.ROADMAP
    //     }
    //     const map = new google.maps.Map(document.getElementById("map"), mapProp)
    // }
    // google.maps.event.addDomListener(window, 'load', initialize)

    /*
     * ----------------------------------------------------------------------------------------
     *  EXTRA JS
     * ----------------------------------------------------------------------------------------
     */
    $(document).on('click', '.navbar-collapse.in', e => {
      if (
        true ===$(e.target).is('a') &&
        'dropdown-toggle' !== $(e.target).attr('class')
      ) {
        $navbarCollapse1.collapse('hide')
      }
    })

    $('body').scrollspy({
      offset: 195,
      target: '.navbar-collapse',
    })

    /*
     * ----------------------------------------------------------------------------------------
     *  WOW JS
     * ----------------------------------------------------------------------------------------
     */
    AOS.init({
      duration: 1e3,
      offset: 0,
    })
    // new WOW().init()

    /*
     * ----------------------------------------------------------------------------------------
     *  UNVEIL.JS
     * ----------------------------------------------------------------------------------------
     */
    $('.unveil').unveil()
  })

  /*
   * ----------------------------------------------------------------------------------------
   *  SERVICE WORKER
   * ----------------------------------------------------------------------------------------
   */
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
      navigator.serviceWorker.register('/sw.js').then(function(registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope)
      }, function(err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err)
      })
    })
  }

})(jQuery);
